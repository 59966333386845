import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from '@/components/HomeComponent.vue'
import DescComponent from '@/components/DescComponent.vue'
import RequiredComponent from '@/components/RequiredComponent.vue'
import TeamComponent from '@/components/TeamComponent.vue'
import LoginComponent from '@/components/LoginComponent.vue'
import PostComponent from '@/components/PostComponent.vue'
import PostDetail from '@/components/PostDetail.vue'
import SignupComponent from '@/components/SignupComponent.vue'
import UserComponent from '@/components/UserComponent.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home', name: 'home' },
  { path: '/home', component: HomeComponent, name: 'home' },
  { path: '/desc', component: DescComponent, name: 'desc' },
  { path: '/required', component: RequiredComponent, name: 'required' },
  { path: '/team', component: TeamComponent, name: 'team' },
  { path: '/login', component: LoginComponent, name: 'login' },
  { path: '/signup', component: SignupComponent, name: 'signup' },
  { path: '/user', component: UserComponent, name: 'user' },
  { path: '/post', component: PostComponent, meta: { hideMenu: true }, name: 'post' },
  { path: '/post/:id', component: PostDetail, props: true, name: 'post-detail', },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export default router
