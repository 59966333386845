<template>
  <div class="login-page">
    <div class="header">
      <div class="login-logo">
        <img src="@/assets/u10.svg" alt="login-logo" />
      </div>
      <div class="title">
        <h1>虚拟仿真平台</h1>
      </div>
    </div>
    <div class="content">
      <div class="login-form">
        <el-form :model="loginForm" label-width="80px">
          <el-form-item label="账号" prop="username">
            <el-input v-model="loginForm.username" class="black-background" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;

export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    };
  },
  created() {
    // 检查localStorage中是否有存储的用户名
    const storedUsername = sessionStorage.getItem('username');
    if (storedUsername && storedUsername !== 'undefined') {
      // 做一些处理，例如在data中设置用户信息
      this.username = storedUsername;
      this.$router.push('/home'); // 请根据实际路由路径进行设置
    }
  },
  methods: {
    login() {
      // 构建请求 URL
      const apiUrl = `${baseUrl}/v1/student/login?studentNo=${this.loginForm.username}&pwd=${this.loginForm.password}`;
      axios.get(apiUrl, {
        headers: {},
      })
        .then(response => {
          this.$message.success("登录成功！");
          sessionStorage.setItem('username', this.loginForm.username);
          var user = response.data;
          user.pwd = "";
          // sessionStorage.setItem('id', user.id);
          // sessionStorage.setItem('name', user.name);
          // sessionStorage.setItem('clazz_no', user.clazz_no);
          // sessionStorage.setItem('student_no', user.student_no);
          // sessionStorage.setItem('clazz_id', user.clazz_id);
          sessionStorage.setItem('userinfo', JSON.stringify(user));
          setTimeout(() => {
            location.reload();
          }, 2000);
          
        })
        .catch(error => {
          console.error(error);
          this.$message.error('登录出错');
          setTimeout(() => {
            location.reload();
          }, 2000);
        });
    },
  }
};
</script>

<style>
body {
  margin: 0;
}
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(40, 42, 48);
  position: relative;
  background-image: url('@/assets/user/u27.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  /* 设置绝对定位，相对于父容器进行定位 */
  top: 0;
  /* 设置子容器距离父容器顶部的距离为0 */
  left: 0;
  color: aliceblue;
}

.login-logo {
  margin-right: 20px;
}

.content {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  /* 设置绝对定位，相对于父容器进行定位 */
  top: 0;
  /* 设置子容器距离父容器顶部的距离为0 */
  right: 0;
  height: 95vh;
  width: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}

.image img {
  flex: 1;
  max-width: 100%;
  height: auto;
}

.login-form {
  flex: 1;
  padding: 20px;
  color: aliceblue;
}

.black-background {
  background-color: black !important;
  color: white;
  /* 可选，设置文本颜色为白色以确保可读性 */
}

.el-input__inner {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: #f0f0f0 !important;
}

.el-card__body, .el-main{
  padding: 0;
}
</style>
