<template>
  <div>
    <el-header v-if="shouldShowMenu">
      <div class="header-content">
        <div class="logo-container">
          <img src="@/assets/u10.svg" alt="Logo" class="logo" />
          <span class="platform-text">虚拟仿真平台</span>
        </div>
        <el-menu :default-active="activeIndex" mode="horizontal" class="centered-menu" @select="handleSelect"
          text-color="white" active-text-color="rgb(33, 152, 217)">
          <el-menu-item index="home" style="margin-left: 50px !important;">首页</el-menu-item>
          <el-menu-item index="desc">实验介绍</el-menu-item>
          <el-menu-item index="required">实验必读</el-menu-item>
          <el-menu-item index="team">项目团队</el-menu-item>
          <el-menu-item index="post">在线答疑</el-menu-item>
          <el-menu-item index="login" class="right-aligned-menu-item" style="margin-left: 200px !important;">
            <el-dropdown>
              <span class="el-dropdown-link" style="font-size: 18px;">
                {{ loginText }}<i v-if="!showSignUp" class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <router-link to="/user" class="no-underline">
                  <el-dropdown-item style="text-decoration: none;">个人中心</el-dropdown-item>
                </router-link>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
          <el-menu-item index="signup" v-if="showSignUp" class="right-aligned-menu-item">注册</el-menu-item>
          <el-menu-item index="signup" v-else-if="!showSignUp" @click="logout"
            class="right-aligned-menu-item">注销</el-menu-item>
        </el-menu>
      </div>
    </el-header>

    <el-main>
      <router-view></router-view>
    </el-main>

    <el-footer v-if="shouldShowMenu" style="text-align: center;">
      <span>版权所有：HHHHHHHH 邮编：72323</span>
      <br>
      <span>技术支持：XXXXX科技有限公司</span>
    </el-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '',
      shouldShowMenu: true,
      loginText: '登录',
      showSignUp: true,
    }
  },
  methods: {
    handleSelect(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.$router.push(`/${index}`);
      }
    },
    logout() {
      // 清空sessionStorage中的所有项目
      sessionStorage.clear();
      this.$message.success("注销成功！");
      setTimeout(() => {
        this.$router.push("/");
        window.location.reload();
      }, 2000);
    },
    checkLoginStatus() {
      const storedUsername = sessionStorage.getItem('username');
      if (storedUsername && storedUsername !== 'undefined') { // 添加判断
        this.loginText = storedUsername; // 设置文本为用户名
        this.showSignUp = false
      }
    },
  },
  watch: {
    // Watch for route changes and update shouldShowMenu accordingly
    $route(to) {
      if (to.path === '/login' || to.path === '/signup') {
        this.shouldShowMenu = false;
      } else {
        this.shouldShowMenu = true;
      }
      this.activeIndex = to.name; 
    }
  },
  created() {
    // 在组件创建时检查当前路由路径
    if (this.$route.path === '/login' || this.$route.path === '/signup') {
      this.shouldShowMenu = false; // 在/post页面不显示菜单
    }
    this.activeIndex = this.$route.name;
    // 在组件创建时检查localStorage中是否有存储的用户名
    this.checkLoginStatus();
  },
}
</script>

<style>
/* 在全局样式中设置整个网站的背景颜色 */
body {
  background-image: url('@/assets/u0.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  padding: 0;
  position: relative; 
  background-color: rgba(5, 20, 40);
}

pre {
  font-size: 20px;
  font-family: Arial, sans-serif;
  color: antiquewhite;
  white-space: pre-line;
  padding: 15px;
  line-height: 1.5;
  /* 行距1.5倍 */
  margin-bottom: 10px;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 半透明背景色，可以自行调整透明度 */
  filter: blur(5px); /* 添加模糊滤镜效果，可以根据需要调整模糊程度 */
  z-index: -1; /* 将伪元素置于 body 的下层 */
}

.container {
  overflow: hidden;
  align-items: flex-start;
  left: 50%;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-menu {
  background-color: transparent !important; 
  color: aliceblue;
}

.el-menu-item.is-active {
  background-color: transparent !important; 
  color: #fff;
}
.el-menu-item:hover {
  background-color: transparent !important; 
  color: aliceblue;
}

.el-menu .el-menu-item.is-active {
  color: white;
  background-color: transparent !important; 
}

.centered-menu .el-menu--horizontal {
  justify-content: center;
  text-align: center;
}

.centered-menu .el-menu-item {
  font-size: 18px;
  font-weight: bolder;
  /* 适当调整水平间距 */
}

/* 将特定的 el-menu-item 靠右显示 */
.right-aligned-menu-item {
  float: right;
  font-size: 18px;
  /* 可以根据需要调整右侧的间距 */
}

.no-underline {
  text-decoration: none;
}
.right-aligned-link {
  float: right;
  margin-right: 20px;
  /* 可以根据需要调整右侧的间距 */
  text-decoration: none;
  /* 去掉链接下划线 */
  color: #fff;
  /* 设置链接颜色 */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  /* 可根据需要调整内边距 */
}

.logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 5px !important;
}

.logo {
  width: 40px;
  /* 根据实际需要调整宽度 */
  height: 40px;
  /* 根据实际需要调整高度 */
  margin-right: 10px;
  /* 可根据需要调整图标和文本之间的间距 */
}

.platform-text {
  color: #fff;
  /* 文本颜色 */
  width: 120px;
  font-size: 15px;
  /* 字体大小，根据实际需要调整 */
  font-weight: bold;
  /* 字体粗细，根据实际需要调整 */
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 100%;
  /* 使文本垂直居中 */
  line-height: 40px;
}</style>