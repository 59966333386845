<template>
  <div class="postdetail-app">
    <div class="postdetail-content">
      <p style="font-size: 20px;">答疑详情</p>
      <div class="postdetail-card-title">
        问题：{{ question.title }}
        &nbsp;提问时间：<span style="font-size: 16px; margin-left: 10px;">{{ question.create_time }}</span>
        <span class="postdetail-card-title-status">{{ question.solved ? '已解决' : '未解决' }}</span>
      </div>
      <div class="postdetail-card-content">提问内容：{{ question.content }}</div>
      <!-- <div style="display: flex; align-items: center;">
        <el-icon class="post-icon" name="view"></el-icon>
        <span class="post-count">{{ question.view_count === null ? 0 : question.view_count }}</span>
        <el-icon class="post-icon" name="thumb"></el-icon>
        <span class="post-count">{{ question.upvote_count === null ? 0 : question.upvote_count }}</span>
        <el-icon class="post-icon" name="message"></el-icon>
        <span class="post-count">{{ question.answers === null ? 0 : question.answers }}</span>
      </div> -->
      <div class="postdetail-card-content">
        附图：
        <img v-if="question.pic" :src="getImageUrl(question.pic)" alt="附图" class="fixed-size-image">
      </div>
      <el-divider></el-divider>

      <!-- 评论区域 -->

      <div v-for="answer in answers" :key="answer.id">
        <el-icon class="post-icon" name="s-custom"></el-icon>
        <span class="post-count">&nbsp;{{ answer.update_by }}</span>
        <span style="font-size: 10px; margin-left: 10px;">{{ answer.create_time }}</span>
        <div class="postdetail-card-content">
          {{ answer.content }}
        </div>
      </div>
    </div>

    <div class="postdetail-bottom">
      <!-- 页码组件，可以使用Element UI的分页组件 -->
      <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="totalItems"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>

const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
const imagePrefix = process.env.VUE_APP_IMAGE_PREFIX;

export default {
  props: {
    title: String, // 接收帖子标题
    content: String, // 接收帖子内容
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 9,
      totalItems: 0,
      answers: [],
      question: {}
    };
  },
  created() {
    this.loadQuestion();
    this.loadReplies(); // 调用方法加载相关回答数据
  },
  methods: {
    getImageUrl(pic) {
      return `${imagePrefix}/${pic}`;
    },
    handlePageChange(currentPage) {
      this.currentPage = currentPage;
    },
    formatLocalTime(utcTimeString) {
      const date = new Date(utcTimeString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    loadQuestion(){
      const questionId = this.$route.params.id; // 从路由参数获取帖子的ID
      fetch(`${baseUrl}/v1/question/getOne/?id=${questionId}`)
        .then((response) => response.json())
        .then((data) => {
          this.question = data;
        })
        .catch((error) => {
          console.error('Error fetching replies:', error);
        });
    },
    loadReplies() {
      // 使用实际的API请求获取相关回答数据
      const questionId = this.$route.params.id; // 从路由参数获取帖子的ID

      fetch(`${baseUrl}/v1/answer/list/${questionId}`)
        .then((response) => response.json())
        .then((data) => {
          this.answers = data.answers;
          this.totalItems = data.total;
        })
        .catch((error) => {
          console.error('Error fetching replies:', error);
        });
    },
  },
};
</script>

<style scoped>
.postdetail-app {
  background-color: rgb(35, 46, 61) !important;
  margin-left: 300px;
  margin-top: 30px;
  display: flex;
  width: 800px;
  /* 固定宽度 */
  height: 600px;
  /* 固定高度 */
  border: 1px solid #ccc;
  /* 边框线 */
  border-radius: 1%;
  display: flex;
  flex-direction: column;
}
.postdetail-card-title{
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.postdetail-card-title-status{
  position: absolute;
  font-size: 20px;
  /* 子元素设置为绝对定位 */
  top: 0;
  /* 子元素顶部对齐父元素顶部 */
  right: 10%;
}
.postdetail-content {
  flex: 1;
  padding: 10px;
  color: white;
  width: 780px;
  white-space: normal;
  word-wrap: break-word;
  overflow: auto;
}

.postdetail-card-content{
  flex: 1;
  padding: 10px;
  color: white;
  background-color: rgb(35, 46, 61) !important;
  white-space: normal;
  word-wrap: break-word;
}

.postdetail-bottom {
  text-align: center; /* 文本居中对齐 */
  margin-bottom: 20px; /* 底部留出一些间距 */
}

.fixed-size-image {
  width: 200px; /* 设置固定宽度，根据需要调整 */
  height: 150px; /* 设置固定高度，根据需要调整 */
  object-fit: cover; /* 保持图片比例并填充整个容器 */
}
</style>
