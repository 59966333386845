<template>
  <el-container>
    <el-main class="home-main1">
      <div class="home-main1-text">
        <p>虚拟仿真平合网络实验</p>
      </div>
      <div class="home-main1-button">
        <a :href="getExperimentLink">
          <el-button type="primary">开始实验</el-button>
        </a>
      </div>
    </el-main>

    <el-main class="home-main2">
      <div class="home-main2-text">
        <p>
          实验简介<br />
          INTRODUCTION TO THE EXPERIMENT
        </p>
      </div>

      <div class="home-main2-buttons">
      <el-button
        class="home-main2-button"
        type="primary"
        plain
        :class="{ 'button-clicked': isTeachingVideoClicked }"
        @click="playTeachingVideo"
      >
        教学引导视频
      </el-button>
      <el-button
        class="home-main2-button"
        type="primary"
        plain
        :class="{ 'button-clicked': isIntroVideoClicked }"
        @click="playIntroVideo"
      >
        项目简介视频
      </el-button>
    </div>

      <div class="home-main2-video">
        <video ref="videoPlayer" controls autoplay>
          <source :src="currentVideoSource" type="video/mp4">
        </video>
      </div>
    </el-main>


    <el-main class="home-main3">
      <!-- 项目团队 -->
      <div class="home-main3-text">
        <p>
          项目团队<br />
          Project Team
        </p>
      </div>

      <div class="home-main3-buttons">
        <el-button class="home-main3-button" type="primary" plain @click="showContent(1)">实验教学项目负责人情况</el-button>
        <el-button class="home-main3-button" type="primary" plain @click="showContent(2)">教学研究情况</el-button>
        <el-button class="home-main3-button" type="primary" plain @click="showContent(3)">学术研究情况</el-button>
      </div>

      <div class="home-main3-cards">
        <el-row>
          <el-card v-show="activeContent === 1" style="background: transparent; border: none;">
            <el-col :span="10" class="home-main3-card-image">
              <img src="@/assets/u75.png" alt="项目负责人图片">
            </el-col>
            <el-col :span="14" class="home-main3-card-text">
              <pre style="font-size: 18px; line-height: 2.5; margin-left: 100px;">
                  {{ dashboardTeam.situatioone }}
              </pre>
            </el-col>
          </el-card>
          <el-card v-show="activeContent === 2" style="background: transparent; border: none;">
            <el-col :span="11" class="home-main3-card-image">
              <img src="@/assets/home_main3_card2.png" alt="教学情况研究">
            </el-col>
            <el-col :span="13" class="home-main3-card-text">
              <pre style="font-size: 18px; line-height: 1.5; margin-left: 20px;">
                  {{ dashboardTeam.situationtwo }}
              </pre>
            </el-col>
          </el-card>
          <el-card v-show="activeContent === 3" style="background: transparent; border: none;">
            <el-col :span="16" class="home-main3-card-text">
              <pre style="font-size: 18px; line-height: 1.5; margin-left: 30px;">
                  {{ dashboardTeam.situationthree }}
                </pre>
            </el-col>
            <el-col :span="8" class="home-main3-card-image">
              <img src="@/assets/home_main3_card3.png" alt="学术情况研究">
            </el-col>
          </el-card>
        </el-row>
      </div>

    </el-main>
    <el-footer>

    </el-footer>
  </el-container>
</template>

<script>
import axios from 'axios';
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
const videoBaseUrl = 'https://uninsdigi-dev.oss-cn-hangzhou.aliyuncs.com/';

export default {
  data() {
    return {
      isTeachingVideoClicked: false,
      isIntroVideoClicked: false,
      dashboardTeam: {
        situatioone: "",
        situationtwo: "",
        situationthree: ""
      },
      dashboardHome: {
        cn_name: "",
        en_name: "",
        col_three: "",
        introduction: "",
        video1: "",
        video2: ""
      },
      currentVideoSource: '',
      activeContent: 1,
    };
  },
  created() {
    axios.get(`${baseUrl}/v1/dashboard/home`)
      .then((response) => {
        this.dashboardHome = response.data.home;
        this.dashboardHome.video1 = videoBaseUrl + this.dashboardHome.video1;
        this.dashboardHome.video2 = videoBaseUrl + this.dashboardHome.video2;
        this.playTeachingVideo();
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    this.loadDashboardTeam();
  },
  computed: {
    getExperimentLink() {
      const baseUrl = 'http://mus.niuyeshuzi.com/GaoZhiMo/';
      // const id = sessionStorage.getItem('id');
      // const name = sessionStorage.getItem('name');
      // const clazz_id = sessionStorage.getItem('clazz_id');
      // const clazz_no = sessionStorage.getItem('clazz_no');
      // const student_no = sessionStorage.getItem('student_no');
      const userinfo = sessionStorage.getItem('userinfo');
      const userinfo64 = window.btoa(userinfo);
      // 如果 sessionStorage 中有 id 和 name，则拼接到 baseUrl 后面
      if (userinfo) {
        return `${baseUrl}?userinfo=${userinfo64}`;
      } else {
        return "/login";
      }
    }
  },
  methods: {
    async loadDashboardTeam() {
      axios.get(`${baseUrl}/v1/dashboard/team`)
        .then((response) => {
          this.dashboardTeam = response.data.team;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
    showContent(contentNumber) {
      this.activeContent = contentNumber;
    },
    playTeachingVideo() {
      this.isTeachingVideoClicked = true;
      this.isIntroVideoClicked = false;
      this.currentVideoSource = this.dashboardHome.video1; // 切换为教学引导视频
      this.$refs.videoPlayer.load();
      this.$refs.videoPlayer.play();
    },
    playIntroVideo() {
      this.isIntroVideoClicked = true;
      this.isTeachingVideoClicked = false;
      this.currentVideoSource = this.dashboardHome.video2; // 切换为项目简介视频
      this.$refs.videoPlayer.load();
      this.$refs.videoPlayer.play();
    }
  },
};
</script>

<style scoped>

* {
  box-sizing: border-box;
}
.el-container {
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  text-align: center;
}

.home-main1 {
  height: 45vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
}

.home-main1-text {
  color: aliceblue;
  font-size: 40px;
}

.home-main1-button {
  font-size: 18px;
  color: aliceblue;
  padding: 10px;
}

.home-main2 {
  height: 110vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
}

.home-main2-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.home-main2-video {
  text-align: center;
  width: 80%;
  /* 设置容器宽度为 80% */
  margin: 0 auto;
  /* 水平居中 */
}

video {
  width: 100%;
  /* 视频宽度为容器的100% */
  height: auto;
  /* 让高度自适应，以保持视频比例 */
}

.home-main2-buttons {
  height: 10vh;
  padding: 10px;
  color: aliceblue;
  text-align: center;
}
.home-main2-button {
  margin: 0 10px;
  cursor: pointer;
  background-color: transparent;
}

.button-clicked {
  background-color: rgb(40, 118, 221) !important;
}

.home-main3 {
  height: 120vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.home-main3-text {
  color: aliceblue;
  font-size: 25px;
}

.home-main3-buttons {
  color: aliceblue;
  display: inline-block;
  padding: 10px;
}

.home-main3-button:hover {
  opacity: 1;
  /* 鼠标悬停时的透明度 */
}

.home-main3-button {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  /* 初始透明度 */
  transition: opacity 0.3s;
  background-color: transparent !important;
}

.home-main3-cards {
  font-size: 18px;
  width: 80%;
  color: aliceblue;
  align-items: flex-start;
  /* 顶部对齐 */
  display: flex;
}

.home-main3-card-image {
  padding: 10px;
  max-width: 100%;
  margin-top: 60px;
}

.home-main3-card-text {
  padding: 40px;
  line-height: 2;
  font-size: 18px;
  white-space: pre-wrap;
  text-align: justify;
}</style>

