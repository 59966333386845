<template>
  <el-container>
    <el-main class="team-main1">
      <div class="team-main1-text">
        <p>虚拟仿真平合项目团队</p>
      </div>
      <div class="team-main1-button">
        <a :href="getExperimentLink">
          <el-button type="primary">开始实验</el-button>
        </a>
      </div>
    </el-main>

    <el-main class="team-main2">
      <div class="team-main2-text">
        <p>
          实验教学项目负责人情况<br />
          PROJECT LEADER
        </p>
      </div>
      <div class="team-main2-one">
        <el-row>
          <el-col :span="12" class="team-main2-one-img">
            <!-- 左侧图片 -->
            <img src="@/assets/team/1.png" alt="项目负责人图片">
          </el-col>
          <el-col :span="12">
            <!-- 右侧文字 -->
            <div class="team-main2-one-text">
              <pre class="team-main2-pre" style="line-height: 2.5;">
                {{ dashboardTeam.situatioone }}
              </pre>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="team-main2-two">
        <el-row>
          <el-col :span="12" class="team-main2-two-img">
            <!-- 左侧图片 -->
            <img src="@/assets/team/2.png" alt="教学研究情况">
          </el-col>
          <el-col :span="12">
            <!-- 右侧文字 -->
            <div class="team-main2-two-text">
              <h3>教学研究情况</h3>
              <pre class="team-main2-pre">
                {{ dashboardTeam.situationtwo }}
              </pre>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="team-main2-three">
        <el-row>
          <el-col :span="12">
            <!-- 右侧文字 -->
            <div class="team-main2-three-text">
              <h3>学术研究情况</h3>
              <pre class="team-main2-pre">
                {{ dashboardTeam.situationthree }}
              </pre>
            </div>
          </el-col>
          <el-col :span="12" class="team-main2-three-img">
            <img src="@/assets/team/3.png" alt="教学研究情况">
          </el-col>
        </el-row>
      </div>

    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios';
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export default {
  data() {
    return {
      dashboardTeam: {
        situatioone: "",
        situationtwo: "",
        situationthree: ""
      },
    };
  },
  created() {
    axios.get(`${baseUrl}/v1/dashboard/team`)
      .then((response) => {
        console.log(response)
        this.dashboardTeam = response.data.team;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      }); 
  },
  methods: {

  },
  computed: {
    getExperimentLink() {
      const baseUrl = 'http://mus.niuyeshuzi.com/GaoZhiMo/';
      // const id = sessionStorage.getItem('id');
      // const name = sessionStorage.getItem('name');
      // const clazz_id = sessionStorage.getItem('clazz_id');
      // const clazz_no = sessionStorage.getItem('clazz_no');
      // const student_no = sessionStorage.getItem('student_no');
      const userinfo = sessionStorage.getItem('userinfo');
      const userinfo64 = window.btoa(userinfo);
      // 如果 sessionStorage 中有 id 和 name，则拼接到 baseUrl 后面
      if (userinfo) {
        return `${baseUrl}?userinfo=${userinfo64}`;
      } else {
        return "/login";
      }
    }
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  text-align: center;
}

.team-main1 {
  height: 45vh;
  background-size: cover;
  background-image: url('@/assets/desc/banner.png');
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.team-main1-text {
  color: aliceblue;
  font-size: 40px;
}

.team-main1-button {
  font-size: 18px;
  color: aliceblue;
  padding: 10px;
}

.team-main2 {
  height: 200vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */

}

.team-main2-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.team-main2-one {
  color: aliceblue;
  height: 70vh;
  padding: 10px;
  width: 80%;
}

.team-main2-one-img {
  padding: 10px;
  max-width: 100%;
  /* 最大宽度为父元素宽度 */
}

.team-main2-one-text {
  white-space: pre-line;
  color: aliceblue;
  padding: 10px;
}

.team-main2-two {
  color: aliceblue;
  height: 60vh;
  padding: 10px;
  width: 80%;

}

.team-main2-two-img {
  padding: 40px;
}

.team-main2-two-text {
  white-space: pre-wrap;
  color: aliceblue;
  padding: 10px;

}

.team-main2-three {
  color: aliceblue;
  height: 70vh;
  padding: 10px;
  width: 80%;

}

.team-main2-three-img {
  padding: 40px;
}

.team-main2-three-text {
  white-space: pre-wrap;
  color: aliceblue;
  padding: 10px;
}

.team-main2-pre {
  font-size: 16px;
  color: white;
  max-width: 100%;
  overflow: auto;
  text-align: justify;
}

</style>