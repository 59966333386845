<template>
  <div class="signup-page">
    <div class="header">
      <div class="signup-logo">
        <img src="@/assets/u10.svg" alt="signup-logo" />
      </div>
      <div class="title">
        <h1>虚拟仿真平台</h1>
      </div>
    </div>
    <div class="content">
      <div class="signup-form">
        <el-form :model="signupForm" label-width="80px">
          <el-form-item label="账号" prop="username">
            <el-input id="username" autocomplete="off" v-model="signupForm.username" :style="{ color: 'red !important' }" class="black-background"
              placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item  autocomplete="off" label="手机号" prop="tel">
            <el-input v-model="signupForm.tel" class="black-background" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item  autocomplete="off" label="验证码" prop="code">
            <el-input v-model="signupForm.code" style="width: 260px;" class="black-background"
              placeholder="验证码"></el-input>
            <el-button @click="sendVerificationCode" type="primary" :disabled="countdown > 0">{{ countdown > 0 ?
              `${countdown} 秒后重试` : '发送验证码' }}</el-button>
          </el-form-item>
          <el-form-item  autocomplete="off" label="密码" prop="password">
            <el-input type="password" v-model="signupForm.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item  autocomplete="off" label="确认密码" prop="confirmPassword">
            <el-input type="password" v-model="signupForm.confirmPassword" placeholder="再次输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="signup" :disabled="isButtonDisabled">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
import axios from 'axios';

export default {
  data() {
    return {
      signupForm: {
        username: '',
        tel: '',
        code: '',
        password: '',
        confirmPassword: '',
      },
      countdown: 0,
      isButtonDisabled: false,
    };
  },
  created() {
    console.log(baseUrl)
  },
  methods: {
    async sendVerificationCode() {
      if (this.countdown > 0) {
        return;
      }
      const telRegex = /^1[3-9]\d{9}$/;
      if (!telRegex.test(this.signupForm.tel)) {
        this.$message.error("手机号格式不符合要求");
        this.signupForm.tel = '';
        return;
      }
      // 开始倒计时
      this.countdown = 60;
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
      try {
        // 发送验证码的API请求
        const response = await axios.get(`${baseUrl}/v1/student/sendVerificationCode?tel=${this.signupForm.tel}`);
        console.log(response);
        // 根据后端API的响应处理成功或失败的逻辑
        if (response.status === 200) {
          this.$message.success(response.data);
        } else {
          // 发送失败，处理失败的逻辑
          console.error('验证码发送失败');
          this.$message.error('验证码发送失败')
        }
      } catch (error) {
        // 处理请求失败的情况
        console.error('请求发送验证码API失败', error);
        this.$message.error('请求发送验证码API失败')
      }
    },
    signup() {
      const regex = /^[a-zA-Z0-9]+$/;
      const telRegex = /^1[3-9]\d{9}$/;
      if (!regex.test(this.signupForm.username)) {
        this.$message.error("用户名需要字母和数字");
        this.signupForm.username = '';
        return ;
      }
      if (!telRegex.test(this.signupForm.tel)) {
        this.$message.error("手机号格式不符合要求");
        this.signupForm.tel = '';
        return ;
      }
      // 验证密码是否一致
      if (this.signupForm.password !== this.signupForm.confirmPassword) {
        this.$message.error("密码不一致");
        this.signupForm.password = ''; // 清空密码
        this.signupForm.confirmPassword = ''; // 清空确认密码
        return ;
      }
      this.isButtonDisabled = true;
      const apiUrl = `${baseUrl}/v1/student/signup?username=${this.signupForm.username}&password=${this.signupForm.password}&tel=${this.signupForm.tel}&code=${this.signupForm.code}`
      if ('' !== this.signupForm.username && '' !== this.signupForm.password) {
        axios.post(apiUrl)
          .then(response => {
            console.log(response)
            this.$message.success("注册成功");
            setTimeout(() => {
              this.$router.push("/login")
            }, 2000);

          })
          .catch(error => {
            console.log(error);
            this.$message.error('注册出错!' + error.response.data.msg);
            setTimeout(() => {
              location.reload();
            }, 2000);
          });
      }
    },
  }
};
</script>

<style>
.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(40, 42, 48);
  position: relative;
  background-image: url('@/assets/user/u27.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  /* 设置绝对定位，相对于父容器进行定位 */
  top: 0;
  /* 设置子容器距离父容器顶部的距离为0 */
  left: 0;
  color: aliceblue;
}

.signup-logo {
  margin-right: 20px;
}

.content {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  /* 设置绝对定位，相对于父容器进行定位 */
  top: 0;
  /* 设置子容器距离父容器顶部的距离为0 */
  right: 0;
  height: 95vh;
  width: 500px;
  background-color: rgba(0, 0, 0, 0.1);
}

.image img {
  flex: 1;
  max-width: 100%;
  height: auto;
}

.signup-form {
  flex: 1;
  padding: 20px;
  color: aliceblue;
}

.black-background {
  background-color: black !important;
  color: white !important;
  /* 可选，设置文本颜色为白色以确保可读性 */
}

.el-input__inner {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: #f0f0f0 !important;
}
</style>
