<template>
  <div class="post-app">
    <div class="post-menu">
      <el-menu :default-active="selectedTab" @select="selectTab" :active-text-color="'rgb(41, 117, 220)'" background-color="#f0f0f0">
        <el-menu-item index="post1" class="post-menu-item" @click="loadPosts">
          答疑大厅
        </el-menu-item>
        <el-menu-item index="post2" class="post-menu-item" @click="showMyPosts">我的答疑</el-menu-item>
        <el-menu-item index="post3" class="post-menu-item" @click="goToOnlineAnswer">在线答疑</el-menu-item>
      </el-menu>
    </div>
    <div class="post-content">
      <div>
        <p v-if="selectedTab === 'post1'">答疑大厅</p>
        <p v-if="selectedTab === 'post2'">我的答疑</p>
        <p v-if="selectedTab === 'post3'">在线答疑</p>
      </div>
      <el-divider></el-divider>

      <el-card :body-style="{ padding: '20px', height: '450px', backgroundColor: 'rgb(17, 23, 40)' }" :border="true" v-if="selectedTab === 'post1'">

        <div class="top-section">
          <div class="top-left">
            <el-button type="text" @click="showHot">热门</el-button>
            <el-button type="text" @click="showLatest">最新</el-button>
          </div>
          <div class="top-right">
            <!-- 页码组件，可以使用Element UI的分页组件 -->
            <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize"
              :total="totalItems" @current-change="handlePageChange">
            </el-pagination>
          </div>
        </div>

        <div class="card-grid">
          <el-card v-for="item in cardItems" :key="item.id"
            :body-style="{ 'color': 'rgb(0, 0, 0)', height: '90px', padding: '20px', 'background-color': 'rgb(255, 255, 255)' }">
            <div class="card-title">
              <router-link :to="`/post/${item.id}`" style="text-decoration: none;">{{ item.title }}</router-link>
              <span style="font-size: 10px; margin-left: 10px;">{{ item.create_time }}</span>
              <span class="card-title-status">{{ item.solved ? '已解决' : '未解决' }}</span>
            </div>
            <div class="post-card-content">{{ item.content }}</div>

            <!-- <div class="post-card-content-post-footer" style="display: flex; align-items: center;">
              <el-icon class="post-icon" name="view"></el-icon>
              <span class="post-count">{{ item.view_count === null ? 0 : item.view_count }}</span>
              <el-icon class="post-icon" name="thumb"></el-icon>
              <span class="post-count">{{ item.upvote_count === null ? 0 : item.upvote_count }}</span>
              <el-icon class="post-icon" name="message"></el-icon>
              <span class="post-count">{{ item.answers === null ? 0 : item.answers }}</span>
            </div> -->
          </el-card>
        </div>
      </el-card>
      <!-- 我的答疑 -->
      <el-card :body-style="{ backgroundColor: 'rgb(17, 23, 40)', padding: '20px', color: 'blue', height: '450px' }" :border="true"
        v-if="selectedTab === 'post2'">

        <div style="margin-left: 385px; margin-bottom: 18px;">
          <!-- 页码组件，可以使用Element UI的分页组件 -->
          <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize"
            :total="totalItems" @current-change="handlePageChange">
          </el-pagination>
        </div>

        <div class="card-grid">
          <el-card v-for="item in cardItems" :key="item.id"
            :body-style="{ 'color': 'rgb(0, 0, 0)', height: '90px', padding: '20px', 'background-color': 'rgb(255, 255, 255)' }">
            <div class="card-title">
              <router-link :to="`/post/${item.id}`" style="text-decoration: none;">{{ item.title }}</router-link>
              <span style="font-size: 10px; margin-left: 10px;">{{ item.create_time }}</span>
              <span class="card-title-status">{{ item.solved ? '已解决' : '未解决' }}</span>
            </div>
            <div class="post-card-content">{{ item.content }}</div>

            <!-- <div class="post-card-content-post-footer" style="display: flex; align-items: center;">
              <el-icon class="post-icon" name="view"></el-icon>
              <span class="post-count">{{ item.view_count === null ? 0 : item.view_count }}</span>
              <el-icon class="post-icon" name="thumb"></el-icon>
              <span class="post-count">{{ item.upvote_count === null ? 0 : item.upvote_count }}</span>
              <el-icon class="post-icon" name="message"></el-icon>
              <span class="post-count">{{ item.answers === null ? 0 : item.answers }}</span>
            </div> -->
          </el-card>
        </div>

        <el-button type="primary" style="margin-left: 420px; margin-top: 20px;" @click="goToOnlineAnswer">去答疑</el-button>
      </el-card>

      <el-card :body-style="{ backgroundColor: 'rgb(17, 23, 40)', padding: '20px', height: '450px' }" :border="true"
        v-if="selectedTab === 'post3'">
        <el-form :model="form" label-width="80px" class="custom-form">
          <el-form-item label="标题">
            <el-input v-model="form.title" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input :rows="4" type="textarea" v-model="form.content" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="附件">
            <el-upload class="upload-demo" :action="uploadAction" :limit="1" :on-success="handleUploadSuccess"
              :on-remove="handleUploadRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button style="margin-left: 220px; margin-top: 0;" type="primary" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export default {
  data() {
    return {
      backendBaseUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      totalItems: 20,
      form: {
        title: '',
        content: '',
        pic: '',
      },
      selectedTab: 'post1',
      pageSize: 8,
      currentPage: 1,
      orderBy: 'create_time',
      createBy: '',
      cardItems: [],
    };
  },
  computed: {
    uploadAction() {
      return `${this.backendBaseUrl}/v1/question/upload`;
    },
  },
  methods: {
    showMyPosts() {
      this.createBy = sessionStorage.getItem('username');
      const username = sessionStorage.getItem('username');
      if (null == username) {
        this.$message.error("请先登录！");
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      } 
      this.loadPosts();
    },
    goToOnlineAnswer() {
      const username = sessionStorage.getItem('username');
      if (null == username) {
        this.$message.error("请先登录！");
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      } else {
        this.selectedTab = "post3"
      }
    },
    showHot() {
      console.log("showHot")
    },
    showLatest() {
      console.log("showLatest")
      this.orderBy = 'create_time';
      this.loadPosts();
    },
    async submitForm() {
      // 处理表单提交逻辑
      const requestData = {
        title: this.form.title,
        content: this.form.content,
        pic: this.form.pic,
        createTime: new Date().toISOString(),
        createBy: sessionStorage.getItem('username'),
        updateBy: sessionStorage.getItem('username'),
        publish: 1,
        solved: 0
      }
      try {
        const response = await fetch(`${baseUrl}/v1/question/addOne`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          // 请求成功
          this.$message.success(
            {
              message: '提问成功',
              type: 'success',
              duration: 2000,
            }
          );
          setTimeout(() => {
            location.reload();
          }, 2000);
        } else {
          // 请求失败
          this.$message.error('提问失败');
        }
      } catch (error) {
        console.error('Error submitting the question:', error);
        this.$message.error('提问时发生错误');
      }
    },
    handleUploadSuccess(response) {
      this.form.pic = response;
    },
    handleUploadRemove(file, fileList) {
      // 处理移除上传文件逻辑
      console.log('移除文件' + file, fileList);
    },
    handlePageChange(currentPage) {
      this.currentPage = currentPage;
      this.loadPosts();
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    async loadPosts() {
      // 构建请求参数
      const params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        orderBy: this.orderBy,
        createBy: this.selectedTab === 'post2' ? this.createBy : '',
      };
      // 构建请求 URL
      const url = `${baseUrl}/v1/question/list`;
      // 将查询参数拼接到 URL
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      const fullURL = `${url}?${queryString}`;

      // 使用实际的API请求获取帖子数据
      const response = await fetch(fullURL);
      const data = await response.json();

      this.cardItems = data.questions;
      this.totalItems = data.total; // 实际应根据API提供的总帖子数量来设置
    }
  },
  created() {
    this.loadPosts();
  }
};
</script>

<style scoped>
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 半透明背景色，可以自行调整透明度 */
  filter: blur(5px); /* 添加模糊滤镜效果，可以根据需要调整模糊程度 */
  z-index: -1; /* 将伪元素置于 body 的下层 */
}

.post-app {
  margin-left: 100px;
  margin-top: 60px;
  display: flex;
  width: 1200px;
  /* 固定宽度 */
  height: 600px;
  /* 固定高度 */
  border: 1px solid #ccc;
  /* 边框线 */
  border-radius: 1%;
}

.post-menu {
  width: 200px;
  padding-top: 100px;
  background-color: transparent !important; 
}

.post-menu-item {
  background-color: transparent !important; 
  color: aliceblue;
  font-size: larger;
  text-align: center;
}

.post-content {
  flex: 1;
  padding: 10px;
  color: white;

  white-space: normal;
  word-wrap: break-word;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.top-left {
  display: flex;
  align-items: center;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  position: relative;
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.card-title-status {
  position: absolute;
  /* 子元素设置为绝对定位 */
  top: 0;
  /* 子元素顶部对齐父元素顶部 */
  right: 0;
  font-size: 10px;
}

.post-card-content {
  font-size: 12px;
  white-space: break-spaces;
  overflow-y: auto;
  max-height: 50px; 
  width: 90%;
  padding: 0;
}

.post-card-content-post-footer {
  margin-left: 130px;
  align-items: center;
  position: absolute;
  font-size: 10px;
}

.post-icon {
  margin-left: 20px;
}

.post-count {
  margin-right: 10px;
}

.upload-demo {
  text-align: center;
  cursor: pointer;
  background-color: transparent !important;
  padding: 10px 0;
}

.fixed-size-content {
  height: 200px !important;
  /* 设置内容高度 */
  min-height: 200px !important;
}

.el-textarea__inner {
  height: 200px !important;
  min-height: 200px !important;
}

.custom-form {
  /* 设置表单容器的背景色 */
  padding: 0px;
  /* 可以添加其他样式属性 */
  background-color: transparent !important;
  width: 600px;
}

.el-form-item .el-form-item__label {
  color: whitesmoke;
}

/deep/ .el-textarea__inner {
  background-color: transparent;
}

</style>
