<template>
  <el-container>
    <el-main class="desc-main1">
      <div class="desc-main1-text">
        <p>虚拟仿真平合实验介绍</p>
      </div>
      <div class="desc-main1-button">
        <a :href="getExperimentLink">
          <el-button type="primary">开始实验</el-button>
        </a>
      </div>
    </el-main>

    <el-main class="desc-main2">
      <div class="desc-main2-text">
        <p>
          实验教学目标<br />
          EXPERIMENTAL TEACHING OBJECTIVES
        </p>
      </div>
      <div class="desc-main2-cards">
        <el-row>
          <el-col :span="8">
            <!-- 第一个卡片 -->
            <el-card
              style="background: transparent; margin: 10px; background-image: url('@/assets/desc/main2-1.png');">
              <el-row>
                <el-col :span="24">
                  <!-- 内容垂直排列 -->
                  <div class="desc-main2-card-content">
                    <img src="@/assets/desc/card1.png" alt="核心理论" />
                    <img src="@/assets/desc/divider.png" />
                    <pre style="font-size: 18px; line-height: 1.5; text-align: justify;">
                      {{ dashboardDesc.suzhimubiao }}
                    </pre>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <!-- 第二个卡片 -->
            <el-card
              style="background: transparent; margin: 10px; background-image: url('@/assets/desc/main5.png');">
              <el-row>
                <el-col :span="24">
                  <!-- 内容垂直排列 -->
                  <div class="desc-main2-card-content">
                    <img src="@/assets/desc/card2.png" alt="掌握技术设备" />
                    <img src="@/assets/desc/divider.png" />
                    <pre style="font-size: 18px; line-height: 1.5; text-align: justify;">
                      {{ dashboardDesc.zhishimubiao }}
                    </pre>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <!-- 第三个卡片 -->
            <el-card
              style="background: transparent; margin: 10px; background-image: url('@/assets/desc/main5.png');">
              <el-row>
                <el-col :span="24">
                  <!-- 内容垂直排列 -->
                  <div class="desc-main2-card-content">
                    <img src="@/assets/desc/card3.png" alt="提高实践能力" />
                    <img src="@/assets/desc/divider.png" />
                    <pre style="font-size: 18px; line-height: 1.5; text-align: justify;">
                      {{ dashboardDesc.nenglimubiao }}
                    </pre>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-main>

    <el-main class="desc-main3">
      <div class="desc-main3-text">
        <p>
          实验原理<br />
          EXPERIMENTAL PRINCIPLES
        </p>
      </div>
      <div class="desc-main3-principle1">
        <el-row>
          <el-col :span="8">
            <!-- 左侧图片 -->
            <img src="@/assets/desc/principle1.png" alt="Image">
          </el-col>
          <el-col :span="16">
            <!-- 右侧文字 -->
            <div class="desc-main3-principles-text">
              <h3>实验原理1</h3>
              <pre style="font-size: 18px; line-height: 1.5; text-align: justify;">
                {{ dashboardDesc.yuanlione }}
              </pre>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="desc-main3-principle2">
        <el-row>
          <el-col :span="16">
            <div class="desc-main3-principles-text">
              <h3>实验原理2</h3>
              <pre style="font-size: 18px; line-height: 1.5; text-align: justify;">
                {{ dashboardDesc.yuanlitwo }}
              </pre>
            </div>
          </el-col>
          <el-col :span="8">
            <img src="@/assets/desc/principle2.png" alt="Image">
          </el-col>
        </el-row>
      </div>
    </el-main>

    <el-main class="desc-main4">
      <div class="desc-main4-text">
        <p>
          实验步骤<br />
          EXPERIMENTAL PROCEDURE
        </p>
      </div>
      <div class="desc-main4-steps">
        <div class="desc-main4-steps-img">
          <img src="@/assets/desc/steps.png" alt="Image">
        </div>
        <div class="desc-main4-steps-contents">
          <el-steps align-center>
            <el-step v-for="step in steps" :key="step.rank" :title="step.stepname" :desc="step.stepcontent"></el-step>
          </el-steps>
        </div>
      </div>
    </el-main>

    <el-main class="desc-main5">
      <div class="desc-main5-text">
        <p>
          实验结果与结论<br />
          EXPERIMENTAL RESULT AND CONCLUSION
        </p>
      </div>
      <div class="desc-main5-content">
        <pre style="font-size: 13px;">
              {{ dashboardDesc.jieguo }}
        </pre>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios';
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;

export default {
  data() {
    return {
      steps: [],
      dashboardDesc: {
        yuanlione: "",
        yuanlitwo: "",
        nenglimubiao: "",
        suzhimubiao: "",
        zhishimubiao: "",
        jieguo: ""
      }
    };
  },
  created() {
    axios.get(`${baseUrl}/v1/dashboard/desc`)
      .then((response) => {
        console.log(response)
        this.dashboardDesc = response.data.desc;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    axios.get(`${baseUrl}/v1/dashboard/steps`)
      .then((response) => {
        this.steps = response.data.steps;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  },
  methods: {
    showText(text) {
      this.hoverText = text;
    },
    hideText() {
      this.hoverText = "";
    },
  },
  computed: {
    getExperimentLink() {
      const baseUrl = 'http://mus.niuyeshuzi.com/GaoZhiMo/';
      // const id = sessionStorage.getItem('id');
      // const name = sessionStorage.getItem('name');
      // const clazz_id = sessionStorage.getItem('clazz_id');
      // const clazz_no = sessionStorage.getItem('clazz_no');
      // const student_no = sessionStorage.getItem('student_no');
      const userinfo = sessionStorage.getItem('userinfo');
      const userinfo64 = window.btoa(userinfo);
      // 如果 sessionStorage 中有 id 和 name，则拼接到 baseUrl 后面
      if (userinfo) {
        return `${baseUrl}?userinfo=${userinfo64}`;
      } else {
        return "/login";
      }
    }
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  text-align: center;
}

.desc-main1 {
  height: 45vh;
  background-size: cover;
  background-image: url('@/assets/desc/banner.png');
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.desc-main1-text {
  color: aliceblue;
  font-size: 40px;
}

.desc-main1-button {
  font-size: 18px;
  color: aliceblue;
  padding: 10px;
}

.desc-main2 {
  height: 80vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.desc-main2-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.desc-main2-cards {
  color: aliceblue;
  padding: 10px;
  width: 80%;
}

.desc-main2-card-content {
  padding: 10px;
  height: 50vh;
  color: aliceblue;
  text-align: center;
}

.desc-main3 {
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.desc-main3-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.desc-main3-principle1 {
  color: aliceblue;
  height: 40vh;
  padding: 10px;
  width: 80%;
  align-items: center;
  align-content: center;
  text-align: center;
}

.desc-main3-principle2 {
  color: aliceblue;
  height: 40vh;
  padding: 10px;
  width: 80%;
  align-items: center;
  align-content: center;
  text-align: center;
}

.desc-main3-principles-text {
  color: aliceblue;
  text-align: justify;
}

.desc-main4 {
  height: 80vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.desc-main4-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.desc-main4-steps {
  height: 60vh;
  color: aliceblue;
  width: 80%;
}

.desc-main4-steps-contents {
  overflow-x: auto;
  white-space: nowrap;
  color: aliceblue;
}

.desc-main5 {
  height: 100vh;
  width: 80%;
  /* 设置最大宽度为页面宽度的80% */
  margin: 0 auto;
  /* 居中显示 */
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}


.desc-main5-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.desc-main5-content {
  background-image: url('@/assets/desc/main5.png');
  background-size: cover;
  width: 80%;
  height: 80vh;
  text-align: justify;
}
</style>
