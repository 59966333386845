<template>
  <el-container>
    <el-main class="required-main1">
      <div class="required-main1-text">
        <p>虚拟仿真平合实验必读</p>
      </div>
      <div class="required-main1-button">
        <a :href="getExperimentLink">
          <el-button type="primary">开始实验</el-button>
        </a>
      </div>
    </el-main>

    <el-main class="required-main2">
      <div class="required-main2-text">
        <p>
          实验必读<br />
          EXPERIMENTAL TEACHING OBJECTIVES
        </p>
      </div>
      <div class="required-main2-cards">
        <el-row>
          <el-col :span="8">
            <!-- 第一个卡片 -->
            <el-card style="background: transparent; margin: 10px;">
              <el-row>
                <el-col :span="24">
                  <!-- 内容垂直排列 -->
                  <div class="required-main2-card-content">
                    <img src="@/assets/required/1.png" alt="宽带要求" />
                    <img src="@/assets/desc/divider.png" />
                    <pre class="required-main2-card-pre">
                      {{ dashboardRequired.wangluoyaoqiu }}
                    </pre>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <!-- 第二个卡片 -->
            <el-card style="background: transparent; margin: 10px;">
              <el-row>
                <el-col :span="24">
                  <!-- 内容垂直排列 -->
                  <div class="required-main2-card-content">
                    <img src="@/assets/required/2.png" alt="配置要求" />
                    <img src="@/assets/desc/divider.png" />
                    <pre class="required-main2-card-pre">
                      {{ dashboardRequired.yingjianyaoqiu }}
                    </pre>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <!-- 第三个卡片 -->
            <el-card style="background: transparent; margin: 10px;">
              <el-row>
                <el-col :span="24">
                  <!-- 内容垂直排列 -->
                  <div class="required-main2-card-content">
                    <img src="@/assets/required/3.png" alt="系统要求" />
                    <img src="@/assets/desc/divider.png" />
                    <pre class="required-main2-card-pre">
                      {{ dashboardRequired.caozuoxitongyaoqiu }}
                    </pre>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>


<script>
import axios from 'axios';
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export default {
  data() {
    return {
      dashboardRequired: {
        wangluoyaoqiu: "",
        yingjianyaoqiu: "",
        caozuoxitongyaoqiu: ""
      }
    };
  },
  created() {
    axios.get(`${baseUrl}/v1/dashboard/required`)
      .then((response) => {
        console.log(response)
        this.dashboardRequired = response.data.required;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  },
  methods: {
    showText(text) {
      this.hoverText = text;
    },
    hideText() {
      this.hoverText = "";
    },
  },
  computed: {
    getExperimentLink() {
      const baseUrl = 'http://mus.niuyeshuzi.com/GaoZhiMo/';
      // const id = sessionStorage.getItem('id');
      // const name = sessionStorage.getItem('name');
      // const clazz_id = sessionStorage.getItem('clazz_id');
      // const clazz_no = sessionStorage.getItem('clazz_no');
      // const student_no = sessionStorage.getItem('student_no');
      const userinfo = sessionStorage.getItem('userinfo');
      const userinfo64 = window.btoa(userinfo);
      // 如果 sessionStorage 中有 id 和 name，则拼接到 baseUrl 后面
      if (userinfo) {
        return `${baseUrl}?userinfo=${userinfo64}`;
      } else {
        return "/login";
      }
    }
  },
};
</script>

<style scoped>
.el-container {
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  text-align: center;
}

.required-main1 {
  height: 45vh;
  background-size: cover;
  background-image: url('@/assets/desc/banner.png');
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.required-main1-text {
  color: aliceblue;
  font-size: 40px;
}

.required-main1-button {
  font-size: 18px;
  color: aliceblue;
  padding: 10px;
}


.required-main2 {
  height: 80vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start;
  /* 主轴上顶部对齐 */
}

.required-main2-text {
  color: aliceblue;
  font-size: 25px;
  height: 20vh;
}

.required-main2-cards {
  color: aliceblue;
  padding: 10px;
  width: 80%;
}
.required-main2-card-content {
  padding: 10px;
  height: 50vh;
  color: aliceblue;
  text-align: center;
}

.required-main2-card-pre {
  font-size: 13px;
  color: white;
  position: absolute;
  max-width: 100%;
  overflow: auto;
  text-align: justify;
}
</style>