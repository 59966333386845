<template>
  <div class="post-app">
    <div class="post-menu">
      <el-menu :default-active="selectedTab" @select="selectTab" :active-text-color="'rgb(41, 117, 220)'" background-color="#f0f0f0">
        <el-menu-item index="post1" class="post-menu-item">
          个人信息
        </el-menu-item>
        <el-menu-item index="post2" class="post-menu-item" @click="getStudentScores">学习成绩</el-menu-item>
        <el-menu-item index="post3" class="post-menu-item">修改密码</el-menu-item>
      </el-menu>
    </div>
    <div class="user-post-content">
      <div>
        <p v-if="selectedTab === 'post1'">个人信息</p>
        <p v-if="selectedTab === 'post2'">学习成绩</p>
        <p v-if="selectedTab === 'post3'">修改密码</p>
      </div>
      <el-divider></el-divider>
      <!-- 个人信息 -->
      <el-card :body-style="{ padding: '20px', 'text-align': 'center', height: '450px', backgroundColor: 'rgb(17, 23, 40)' }" :border="true"
        v-if="selectedTab === 'post1'">
        <div class="top-section">
          <el-form :model="userinfo" label-width="80px">
            <el-form-item label="账号">
              <el-input v-model="userinfo.student_no" :border="false" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="userinfo.name"></el-input>
            </el-form-item>
            <!-- <el-form-item label="性别">
              <el-radio-group v-model="userinfo.gender">
                <el-radio label=true>男</el-radio>
                <el-radio label=false>女</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="手机号">
              <el-input v-model="userinfo.tel"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveUserInfo">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>

      <!-- 学习成绩 -->
      <el-card :body-style="{ padding: '20px', height: '450px', position: 'relative', backgroundColor: 'rgb(17, 23, 40)' }" :border="true"
        v-if="selectedTab === 'post2'">

        <el-table :header-cell-style="{background:'rgb(17, 23, 40)',color:'white', 'font-size':'18px'}" :border="true" :cell-style="{background:'rgb(17, 23, 40)',color:'white'}" style="width: 100%" :data="scores"
          class="custom-table">
          <el-table-column align="center" label="ID" prop="id"></el-table-column>
          <el-table-column align="center" label="课程编号" prop="courseNo"></el-table-column>
          <el-table-column align="center" label="教师编号" prop="teacherNo"></el-table-column>
          <el-table-column align="center" label="成绩1" prop="mark1"></el-table-column>
          <el-table-column align="center" label="成绩2" prop="mark2"></el-table-column>
          <el-table-column align="center" label="总成绩" prop="mark"></el-table-column>
        </el-table>
        <div class="custom-table-page">
          <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20]"
            :page-size="pageSize" :total="totalItems" />
        </div>

      </el-card>

      <!-- 修改密码 -->
      <el-card :body-style="{ 'color': 'rgb(0, 0, 0)', padding: '20px', height: '450px', backgroundColor: 'rgb(17, 23, 40)' }" :border="true"
        v-if="selectedTab === 'post3'">
        <div class="top-section">
          <el-form :model="userinfo" label-width="80px">
            <el-form-item label="账号">
              <el-input v-model="userinfo.student_no" :border="false" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="userinfo.name" :border="false" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="userinfo.tel" :border="false" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="旧密码">
              <el-input type="password" v-model="userinfo.pwd"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input type="password" v-model="userinfo.newpwd"></el-input>
            </el-form-item>
            <el-form-item label="重复密码">
              <el-input type="password" v-model="userinfo.newpwd2"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="updatePwd">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
console.log(baseUrl)
import axios from 'axios';

export default {
  data() {
    return {
      selectedTab: 'post1',
      scores: [],
      currentPage: 1,
      pageSize: 5,
      totalItems: 0,
      userinfo: {
        gender: true,
      }
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    handleCurrentChange(currentPage) {
      // 分页变化时，更新当前页数并重新获取数据
      this.currentPage = currentPage;
      this.getStudentScores();
    },
    getStudentScores() {
      const studentid = this.userinfo.id;
      console.log(studentid);
      axios.get(`${baseUrl}/v1/score/list2?studentNo=${studentid}&pageNo=${this.currentPage}&pageSize=${this.pageSize}`)
        .then(response => {
          console.log(response);
          this.scores = response.data.scores;
          this.totalItems = response.data.totalCount;
        })
        .catch(error => {
          console.error('获取学生成绩失败:', error);
        });
    },
    updatePwd() {
      console.log(this.userinfo)
      const userData = this.userinfo;
      if (this.userinfo.newpwd2 !== this.userinfo.newpwd) {
        this.$message.error("密码不一致");
        this.userinfo.newpwd = '';
        this.userinfo.newpwd2 = '';
      } else {
        userData.pwd = this.userinfo.newpwd;
        userData.studentNo = userData.student_no;
        userData.clazzNo = userData.clazz_no;
        userData.clazzId = userData.clazz_id;
        const colid = userData.id;
        axios.post(`${baseUrl}/v1/student/update/${colid}`, userData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            console.log('用户信息已保存:', response.data);
            this.$message.success("保存成功");
            setTimeout(() => {
              this.selectedTab = 'post1'
            }, 2000);
          })
          .catch(error => {
            console.error('保存用户信息失败:', error);
            this.$message.success("保存用户信息失败");
            setTimeout(() => {
              location.reload();
            }, 2000);
          });
      }
    },
    saveUserInfo() {
      console.log(this.userinfo);
      const userData = this.userinfo;
      userData.studentNo = userData.student_no;
      userData.clazzNo = userData.clazz_no;
      userData.clazzId = userData.clazz_id;
      const colid = userData.id;
      axios.post(`${baseUrl}/v1/student/update/${colid}`, userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log('用户信息已保存:', response.data);
          this.$message.success("保存成功");
          setTimeout(() => {
            this.selectedTab = 'post1'
          }, 2000);
        })
        .catch(error => {
          console.error('保存用户信息失败:', error);
          this.$message.success("保存用户信息失败");
          setTimeout(() => {
            location.reload();
          }, 2000);
        });

    },
    getUserInfo() {
      const studentNo = sessionStorage.getItem("username")
      if (studentNo) {
        axios.get(`${baseUrl}/v1/student/getOne?studentNo=${studentNo}`)
          .then(response => {
            this.userinfo = response.data; // 更新userinfo对象
          })
          .catch(error => {
            console.error('获取用户信息失败:', error);
          });
      } else {
        this.$message.error("请先登录！");
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      }

    },
  }
}
</script>

<style>
.custom-table {
  position: absolute;
  width: 90%;
  background-color: rgb(35, 46, 61);
  text-align: center;
  /* 文本居中 */
  padding: 10px;
  height: 90%;
  top: 0;
  left: 0;
}

.custom-table-page {
  position: absolute;
  width: 100%;
  /* 子 div 占满容器的宽度 */
  text-align: center;
  /* 文本居中 */
  padding: 10px;
  bottom: 0;
  left: 0;
  background-color: rgb(35, 46, 61);
}

.table-wrapper /deep/ .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

.table-wrapper /deep/ .el-table tr {
  background-color: transparent !important;
}

.table-wrapper /deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

.custom-table .el-table__body td {
  color: rgb(35, 46, 61)
}

.post-app {
  margin-left: 100px;
  margin-top: 30px;
  display: flex;
  width: 1200px;
  /* 固定宽度 */
  height: 600px;
  /* 固定高度 */
  border: 1px solid #ccc;
  /* 边框线 */
  border-radius: 1%;
}

.post-menu {
  width: 200px;
  padding-top: 100px;
  background-color: transparent !important; 
}

.post-menu-item {
  background-color: transparent !important; 
  color: aliceblue;
  font-size: larger;
  text-align: center;
}

.user-post-content {
  flex: 1;
  padding: 20px;
  color: white;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.top-left {
  display: flex;
  align-items: center;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  position: relative;
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.card-title-status {
  position: absolute;
  /* 子元素设置为绝对定位 */
  top: 0;
  /* 子元素顶部对齐父元素顶部 */
  right: 0;
  font-size: 10px;
}

.card-content {
  font-size: 12px;
  white-space: normal;
  overflow: hidden;
  height: 50px;
  width: 90%;
  text-overflow: ellipsis;
}

.card-content-post-footer {
  margin-left: 130px;
  align-items: center;
  position: absolute;
  font-size: 10px;
}

.post-icon {
  margin-left: 20px;
}

.post-count {
  margin-right: 10px;
}

.upload-demo {
  text-align: center;
  border: 1px dashed #409EFF;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.2s;
  background-color: #f0f0f0;
  padding: 20px 0;
}

.upload-demo:hover {
  border-color: #409EFF;
}

.fixed-size-content {
  height: 200px !important;
  /* 设置内容高度 */
  min-height: 200px !important;
}

.el-textarea__inner {
  height: 200px !important;
  min-height: 200px !important;
}

.custom-form {
  background-color: rgb(35, 46, 61);
  /* 设置表单容器的背景色 */
  padding: 0px;
  /* 可以添加其他样式属性 */
  color: #ccc;
  width: 600px;
}

.el-form-item .el-form-item__label {
  color: whitesmoke;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .black {
  color: white;
}

/deep/ .el-table .el-table__expanded-cell{
  background-color: rgb(35, 46, 61);
  background: transparent;
}

</style>
